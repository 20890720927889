import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '@/views/Home.vue';
import AIML from '@/views/AIML.vue';
import Payment from '@/views/Payment.vue';

import FaceRecognition from '@/components/AIML/FaceRecognition/FaceRecognition.vue';
import Paytm from '@/components/Payment/Paytm.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/payment',
    name: 'Payment',
    component: Payment,
    children: [
      {
        path: 'paytm',
        name: 'Paytm',
        component: Paytm,
      }
    ]
  },
  {
    path: '/ai-ml',
    name: 'AIML',
    component: AIML,
    children: [
      {
        path: 'face-recognition',
        name: 'FaceRecognition',
        component: FaceRecognition,
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
