
import { Options, Vue } from 'vue-class-component';
import axios from 'axios';
import FindInImages from './FindInImages.vue';

@Options({
  name: 'FaceRecognition',
  components: {
    FindInImages,
  },
})
export default class FaceRecognition extends Vue {
	private api = axios.create({
		baseURL: process.env.VUE_APP_API_BASE_URL + '/ai-ml',
		headers: {
		JWT: process.env.VUE_APP_API_JWT_TOKEN
		}
	}); 
}
