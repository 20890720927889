
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'Paytm',
	props: ['api'],
	data() {
		return {
			transaction: {
				orderId: 'test-order-' + Math.random().toString().replace('.', ''),
				currency: 'INR',
				amount: null,
				customerId: '5349b4ddd2781d08c09890f3'
			},
			paytm: {
				mid: '',
				orderId: '',
				txnToken: '',
				postURL: ''
			},
			error: ''
		}
	},
	methods: {
		async getTransactionToken() {
			const resp = await this.api({
				url: '/paytm/create-transaction-token',
				method: 'POST',
				data: this.transaction
			});
			if(resp.data.success) {
				this.paytm = resp.data.data;
				this.paytm.postURL += `?orderId=${this.paytm.orderId}&mid=${this.paytm.mid}`;
				this.redirectToPaytm();
			} else {
				this.error = resp.data.message;
			}
		},
		redirectToPaytm() {
			const paytmForm = document.forms.namedItem('paytm-form') as HTMLFormElement;
			paytmForm.action = this.paytm.postURL;
			paytmForm.submit();
		}
	}
});

