
import { Options, Vue } from 'vue-class-component';
import FaceRecognition from '@/components/AIML/FaceRecognition/FaceRecognition.vue';

@Options({
  name: 'AIML',
  components: {
    FaceRecognition,
  },
})
export default class AIML extends Vue {}
