
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'FindInImages',
	props: ['api'],
	data() {
		return {
			result: [],
			error: ''
		}
	},
	methods: {
		async findInImages() {
			const form = new FormData(document.forms[0]);
			const resp = await this.api({
				url: '/face-recognition/find-in-images',
				method: 'POST',
				data: form
			});
			if(resp.data.success) {
				this.result = resp.data.data.matches;
			} else {
				this.error = resp.data.message;
			}
		}
	}
});

