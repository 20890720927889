
import { Options, Vue } from 'vue-class-component';
import axios from 'axios';
import Paytm from '@/components/Payment/Paytm.vue';

@Options({
  components: {
    Paytm,
  },
})
export default class Home extends Vue {
  private api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL + '/payment',
    headers: {
      JWT: process.env.VUE_APP_API_JWT_TOKEN
    }
  }); 
}
